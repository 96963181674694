
<section class="wrapper" [ngClass]="{ loader: loaderView }">
  <app-loader class="loader" *ngIf="loaderView"></app-loader>
  <section class="popup-header">
    <div class="header-text">
      <img [src]="assetDetails.iconUrl ? assetDetails.iconUrl : 'assets/144x144/asset-default.svg'"  alt=""/>
      <div class="header">
        <h1 class="primary-header">{{ assetDetails.name }}</h1>
        <div class="description">
          <span class="label">Asset Code Identifier:</span>
          <span class="value">{{ assetDetails.assetTypeCode }}</span>
        </div>
        <div class="description">
          <span class="label">Created On:</span>
          <span class="value">{{
            assetDetails.createdAt | date : "dd/MM/yyyy"
          }}</span>
        </div>
      </div>

    </div>
    <div class="edit-button" (click)="onEditAssetType()">
      <img
      src="assets/14x14/edit_blue.svg"
      alt="Edit"
      class="edit-icon"
    />
      <span >Edit Asset Type</span>
    </div>
    <div>
      <div class="close" (click)="closeDialog()">
        <img src="assets/24x24/close.svg" alt="" />
      </div>
    </div>
  </section>

  <div *ngFor="let category of assetDetails.categories" class="card-container">
    <h1>{{ category.displayName }}</h1>
    <div *ngFor="let config of groupedConfigs[category.name]">
      <div class="config-item">
        <span class="labels">{{ config.displayName }}</span>
        <span>:</span>
        <!-- <div  class="config-item-values"> -->
        <span class="values">
          <ng-container *ngIf="config.list">
            <p class="values-heading">Value(s):</p>
            {{ config.values.join(", ") }}
          </ng-container>
          <ng-container *ngIf="!config.list">
            <p class="values-heading">Value(s):</p>
            {{ config.values[0] || "NA" }}
          </ng-container>
        </span>
        <span class="unit" *ngIf="config.displayName !== 'Location'">
          <ng-container *ngIf="config.units.length">
            <p class="values-heading">Unit(s):</p>
            {{ config.units.join(", ") }}
          </ng-container>
          <ng-container *ngIf="!config.units.length">
            <p class="values-heading">Unit(s):</p>
            {{ config.units[0] || "NA" }}
          </ng-container>
        </span>
      </div>
    </div>
  </div>
  <!-- </div> -->
</section>
