import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ROUTES } from 'libs/common/src/lib/constants/routes.constants';
import { CommonHttpService } from 'libs/common/src/lib/service/common-http.service';

@Component({
  selector: 'app-accessory-type-detail',
  templateUrl: './accessory-type-detail.component.html',
  styleUrls: ['./accessory-type-detail.component.scss']
})
export class AccessoryTypeDetailComponent implements OnInit {
  accessoryTypeId!: string;
  accessoryDetails: any;
  groupedConfigs: { [key: string]: any[] } = {};
  loaderView: boolean = false;
  constructor(
    private commonHttpService: CommonHttpService,
    @Inject(DIALOG_DATA) public data: any,
    private dialogRef: DialogRef,private router: Router,  private activatedRoute: ActivatedRoute,
  ) {
    this.accessoryTypeId = data.id;
  }

  ngOnInit(): void {
    this.fetchAccessoryDetails();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  fetchAccessoryDetails() {
    this.loaderView =  true;
    this.commonHttpService.getAccessoryTypeDetails(this.accessoryTypeId).subscribe({
      next: (response: any) => {
        this.accessoryDetails = response.data;
        this.groupConfigsByCategory();
        this.loaderView =  false;
      },
      error: (error) => {
        console.error('Error fetching asset details:', error);
      }
    });
  }

  groupConfigsByCategory() {
    this.accessoryDetails.configs.forEach((config: any) => {
      if (!this.groupedConfigs[config.category]) {
        this.groupedConfigs[config.category] = [];
      }
      this.groupedConfigs[config.category].push(config);
    });
  }

  onEditAccessoryType() {
    this.router.navigate([ROUTES.ADD_ACCESSORY_TYPE], {
      relativeTo: this.activatedRoute,
      queryParams: {
        id: this.accessoryTypeId,
        name: this.accessoryDetails.name,
        editMode: true,
      },
    });
    this.closeDialog();
  }
}
