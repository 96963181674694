import { MANAGE_WORKSPACE } from "./constants";


export const ROUTES = {
  SIGN_IN: 'signin',
  ASSET_TEAM_DASHBOARD: '/home/asset-team-dashboard',
  ASSET_TEAM_DASHBOARD_PERSONAL: '/home/asset-team-dashboard/personal',
  ADMIN_TICKET: '/home/admin-ticket',
  ADMIN_TICKET_PERSONAL: '/home/admin-ticket/personal',
  ADMIN_APPROVAL_PERSONAL: '/home/admin-approval/personal',
  ADMIN_TICKETS_ONGOING: 'home/admin-ticket?status=ONGOING',
  ADMIN_TICKETS_TO_DO: 'home/admin-ticket?status=TO_DO',
  ADMIN_TICKETS_CLOSED: 'home/admin-ticket?status=CLOSED',
  ADMIN_TICKETS_EXTERNAL: 'home/admin-ticket?status=EXTERNAL',
  ADMIN_TICKETS_ALL: 'home/admin-ticket?status=ALL',
  TICKET_DETAILS: 'home/admin-ticket/ticket/',
  ADMIN_INVENTORY: '/home/inventory',
  ADMIN_INVENTORY_ALLOCATED: 'home/inventory?status=ALLOCATED',
  ADMIN_INVENTORY_AVAILABLE: 'home/inventory?status=AVAILABLE',
  ADMIN_INVENTORY_OUT_FOR_REPAIR: 'home/inventory?status=OUT_FOR_REPAIR',
  ADMIN_INVENTORY_DISABLED: 'home/inventory?status=DISABLED',
  ADMIN_INVENTORY_ALL: '/home/inventory?status=ALL',
  ASSET_DETAILS: '/home/inventory/asset-details/',
  ADD_ASSET: 'home/inventory/asset',
  EDIT_ASSET: '/home/inventory/asset',
  ADMIN_BULK_UPLOAD: 'home/bulk-upload',
  MANAGE_WORKSPACE_OWNER:'/home/manage-workspace-owner',
  ADD_ASSET_TYPE:'/home/manage-workspace-owner/add-asset-type',
  ADD_ACCESSORY_TYPE:'/home/manage-workspace-owner/add-accessory-type',
  SUPER_ADMIN_MANAGE_WORKSPACE: '/home/manage-workspace',
  MANAGE_WORKSPACE_DETAILS: '/home/manage-workspace/workspace-details',
  PRIVACY_POLICY: 'home/help/privacy-policy',
  TERMS_AND_CONDITIONS: 'home/help/terms-and-conditions',
  PROFILE_DETAILS: 'home/profile-details/',
  LOANEE_PROFILE: '/home/employee-details/',
  USER_DASHBOARD: '/home/employee-dashboard',
  USER_APPROVAL: '/home/employee-approval',
  USER_TICKET_DETAILS: '/home/employee-dashboard/ticket/',
}
