import {
  Component,
  OnInit,
  ChangeDetectorRef,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  UntypedFormGroup,
  UntypedFormControl,
  FormControl,
  FormArray,
} from '@angular/forms';
import { CommonHttpService } from 'libs/common/src/lib/service/common-http.service';
import { CommonService } from 'libs/common/src/lib/service/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Dialog } from '@angular/cdk/dialog';
import { ConfirmationPopupWithFormComponent } from 'libs/shared/ui-components/src/lib/confirmation-popup-with-form/confirmation-popup-with-form.component';
import { ROUTES } from 'libs/common/src/lib/constants/routes.constants';

@Component({
  selector: 'app-add-asset-type',
  templateUrl: './add-asset-type.component.html',
  styleUrls: ['./add-asset-type.component.scss'],
})
export class AddAssetTypeComponent implements OnInit {
  assetTypeForm: FormGroup;
  isEditMode: boolean = false;
  assetDetails: any;
  accordionState: boolean[] = [false, false, false];
  purchaseDateValues: string[] = [];
  purchaseDateUnits: string[] = [];
  purchaseCostValues: string[] = [];
  purchaseCostUnits: string[] = [];
  warrantyValues: string[] = [];
  warrantyUnits: string[] = [];
  invoiceNumberValues: string[] = [];
  invoiceNumberUnits: string[] = [];
  locations: any;
  issuesValues: string[] = [];
  selectedCount: number = 0;
  groupAccordionState: { [key: string]: boolean } = {};
  groupedProperties: { groupName: string; properties: any[] }[] = [];
  @Output() formSubmitted = new EventEmitter<void>();
  groupMenuState: { [key: string]: boolean } = {};
  properties: {
    property: string;
    value: string[];
    unit: string[];
    isRequired: boolean;
    checked: boolean;
  }[] = [];
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private commonHttpService: CommonHttpService,
    private commonService: CommonService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private cdr: ChangeDetectorRef,
    private dialog: Dialog
  ) {
    this.assetTypeForm = this.fb.group({
      name: ['', Validators.required],
      assetTypeCode: ['', Validators.required],
      id: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.isEditMode = false;
    this.route.queryParams.subscribe((params) => {
      this.assetTypeForm.patchValue({
        name: params['name'] || '',
        assetTypeCode: params['assetTypeCode'] || '',
        id: params['id'] || '',
      });
      this.isEditMode = params['editMode'] === 'true'; // Set edit mode based on query parameter
    });
    this.getLocations();
    if (this.isEditMode) {
      this.fetchAssetDetails();
    }
  }

  fetchAssetDetails() {
    const formData = this.assetTypeForm.value;
    const id = formData.id;
    this.commonHttpService.getAssetTypeDetails(id).subscribe({
      next: (response: any) => {
        this.assetDetails = response.data;
        this.isEditMode = true;
        this.groupedProperties = this.groupConfigsByCategory(
          this.assetDetails.configs,
          this.assetDetails.categories
        );
      },
      error: (error: any) => {
        console.error('Error fetching asset details:', error);
      },
    });
  }
  groupConfigsByCategory(
    configs: any[],
    categories: any
  ): { groupName: string; properties: any[] }[] {
    const grouped = configs.reduce((acc: any, config: any) => {
      const category = categories?.find(
        (category: any) => category.name === config.category
      );

      if (!acc[category?.displayName]) {
        acc[category.displayName] = {
          groupName: category.displayName,
          properties: [],
        };
      }
      acc[category.displayName].properties.push(config);
      return acc;
    }, {});

    return Object.values(grouped);
  }
  toggleGroupMenu(groupName: string): void {
    this.groupMenuState[groupName] = !this.groupMenuState[groupName];
  }
  onAddProperty(): void {
    const formData = {
      header: 'Add Property',
      description: 'Please enter the property and unit, value(s) if any',
      image: 'cautionImage',
      form: {
        confirmationForm: new UntypedFormGroup({
          property: new UntypedFormControl('', [Validators.required]),
          value: new UntypedFormControl(''),
          unit: new UntypedFormControl(''),
        }),
      },
      fields: [
        {
          field: 'text-field',
          controlName: 'property',
          placeholder: 'E.g., RAM size',
          label: 'Property',
        },
        {
          field: 'text-fields',
          controlName: 'value',
          placeholder: 'E.g., 4, 8, 16 etc.',
          label: 'Value(s)',
        },
      ],
      primaryBtn: 'Add',
      isEditMode: this.isEditMode,
    };

    const dialogRef = this.dialog.open(ConfirmationPopupWithFormComponent, {
      panelClass: 'center-dialog-box',
      backdropClass: 'backdrop',
      disableClose: true,
      width: '50%',
      data: formData,
    });
    dialogRef.closed.subscribe((result: any) => {
      if (result) {
        this.properties.push({
          property: result.property,
          value: result.value
            ? Array.isArray(result.value)
              ? result.value
              : [result.value]
            : [],
          unit: result.unit
            ? Array.isArray(result.unit)
              ? result.unit
              : [result.unit]
            : [],
          isRequired: result.isRequired,
          checked: false,
        });
      }
    });
  }

  onEditProperty(groupIndex: number | null, propertyIndex: number): void {
    let property: any;
    if (groupIndex !== null) {
      // Handle grouped property
      const group = this.groupedProperties[groupIndex];
      property = group.properties[propertyIndex];
    } else {
      // Handle ungrouped property
      property = this.properties[propertyIndex];
    }
    if (!property) {
      return;
    }

    const formData = {
      header: 'Edit Property',
      description: 'Modify the property details',
      image: 'editImage',
      form: {
        confirmationForm: new FormGroup({
          property: new FormControl(property.property || property.displayName, [
            Validators.required,
          ]),
          value: new FormControl(
            property.value?.join(', ') || property.values?.join(', ')
          ),
          unit: new FormControl(
            property.unit?.join(', ') || property.units?.join(', ')
          ),
          isRequired: new FormControl(property.isRequired),
        }),
      },
      fields: [
        {
          field: 'text-field',
          controlName: 'property',
          placeholder: 'Property Name',
          label: 'Property',
        },
        {
          field: 'text-fields',
          controlName: 'value',
          placeholder: 'Values (comma-separated)',
          label: 'Value(s)',
        },

        {
          field: 'checkbox',
          controlName: 'isRequired',
          label: 'Required',
        },
      ],
      primaryBtn: 'Save',
      isEditMode: this.isEditMode,
    };
    const dialogRef = this.dialog.open(ConfirmationPopupWithFormComponent, {
      panelClass: 'center-dialog-box',
      backdropClass: 'backdrop',
      disableClose: true,
      width: '50%',
      data: formData,
    });
    dialogRef.closed.subscribe((result: any) => {
      if (result) {
        const updatedProperty = {
          property: result.property,
          value: result.value
            ? result.value?.split(',')?.map((v: string) => v.trim())
            : [],
          unit: result.unit
            ? result.unit?.split(',')?.map((u: string) => u.trim())
            : [],
          isRequired: result.isRequired,
          checked: property.checked,
        };

        if (groupIndex !== null) {
          // Update grouped property
          this.groupedProperties[groupIndex].properties[propertyIndex] =
            updatedProperty;
        } else {
          // Update ungrouped property
          this.properties[propertyIndex] = updatedProperty;
        }
      }
    });
  }
  onEditDetail(fieldName: string): void {
    let existingValues: string[] = [];
    let existingUnits: string[] = [];

    switch (fieldName) {
      case 'Purchase Date':
        existingValues = this.purchaseDateValues;
        existingUnits = this.purchaseDateUnits;
        break;
      case 'Purchase Cost':
        existingValues = this.purchaseCostValues;
        existingUnits = this.purchaseCostUnits;
        break;
      case 'Warranty':
        existingValues = this.warrantyValues;
        existingUnits = this.warrantyUnits;
        break;
      case 'Invoice Number':
        existingValues = this.invoiceNumberValues;
        existingUnits = this.invoiceNumberUnits;
        break;
    }

    const formData = {
      header: `Edit ${fieldName}`,
      description: `Edit the ${fieldName} and add value(s) and unit(s)`,
      image: 'cautionImage',
      form: {
        confirmationForm: new FormGroup({
          property: new FormControl(fieldName, [Validators.required]),
          value: new FormControl(existingValues.join(', ')), // Pre-fill with existing values
          unit: new FormControl(existingUnits.join(', ')), // Pre-fill with existing units
          isRequired: new FormControl(false),
        }),
      },
      fields: [
        {
          field: 'text-field',
          controlName: 'property',
          placeholder: 'Property Name',
          label: 'Property',
        },
        {
          field: 'text-fields',
          controlName: 'value',
          placeholder: 'Add values',
          label: 'Value(s)',
        },
      ],
      primaryBtn: 'Save',
    };

    const dialogRef = this.dialog.open(ConfirmationPopupWithFormComponent, {
      panelClass: 'center-dialog-box',
      backdropClass: 'backdrop',
      disableClose: true,
      width: '50%',
      data: formData,
    });

    dialogRef.closed.subscribe((result: any) => {
      if (result) {
        switch (fieldName) {
          case 'Purchase Date':
            this.purchaseDateValues = Array.isArray(result.value)
              ? result.value
              : [result.value];
            this.purchaseDateUnits = Array.isArray(result.unit)
              ? result.unit
              : [result.unit];
            break;
          case 'Purchase Cost':
            this.purchaseCostValues = Array.isArray(result.value)
              ? result.value
              : [result.value];
            this.purchaseCostUnits = Array.isArray(result.unit)
              ? result.unit
              : [result.unit];
            break;
          case 'Warranty':
            this.warrantyValues = Array.isArray(result.value)
              ? result.value
              : [result.value];
            this.warrantyUnits = Array.isArray(result.unit)
              ? result.unit
              : [result.unit];
            break;
          case 'Invoice Number':
            this.invoiceNumberValues = Array.isArray(result.value)
              ? result.value
              : [result.value];
            this.invoiceNumberUnits = Array.isArray(result.unit)
              ? result.unit
              : [result.unit];
            break;
        }
      }
    });
  }
  // toggleCheckbox(index: number): void {
  //   this.properties[index].checked = !this.properties[index].checked;
  // }
  toggleAccordion(index: number): void {
    this.accordionState[index] = !this.accordionState[index];
  }

  getLocations() {
    this.commonHttpService.getLocations().subscribe((res: any) => {
      if (res && res.data) {
        this.locations = res.data;
      } else {
        this.locations = [];
      }
    });
  }
  toggleCheckbox(index: number): void {
    this.properties[index].checked = !this.properties[index].checked;
    this.updateSelectedCount();
  }

  updateSelectedCount(): void {
    this.selectedCount = this.properties.filter((prop) => prop.checked).length;
  }

  clearSelection(): void {
    this.properties.forEach((prop) => (prop.checked = false));
    this.updateSelectedCount();
  }
  onGroupSelection(): void {
    const formData = {
      header: 'Group Selection',
      description: 'Enter details for the group selection',
      image: 'cautionImage',
      form: {
        confirmationForm: new UntypedFormGroup({
          groupName: new UntypedFormControl('', [Validators.required]),
        }),
      },
      fields: [
        {
          field: 'text-field',
          controlName: 'groupName',
          placeholder: 'Enter group name',
          label: 'Group Name',
        },
      ],
      primaryBtn: 'Save',
    };

    const dialogRef = this.dialog.open(ConfirmationPopupWithFormComponent, {
      panelClass: 'center-dialog-box',
      backdropClass: 'backdrop',
      disableClose: true,
      width: '50%',
      data: formData,
    });

    dialogRef.closed.subscribe((result: any) => {
      if (result) {
        const selectedProperties = this.properties.filter(
          (prop) => prop.checked
        );
        this.groupedProperties.push({
          groupName: result.groupName,
          properties: selectedProperties,
        });

        // Remove grouped properties from the main list
        this.properties = this.properties.filter((prop) => !prop.checked);

        // Clear selection
        this.clearSelection();
      }
    });
  }
  toggleGroupAccordion(groupName: string): void {
    this.groupAccordionState[groupName] = !this.groupAccordionState[groupName];
  }
  onEditIssues(): void {
    const formData = {
      header: 'Edit Issues',
      description: 'Please enter the issue details',
      image: 'cautionImage',
      form: {
        confirmationForm: new UntypedFormGroup({
          property: new UntypedFormControl('Issues', [Validators.required]), // Pre-fill with 'Issues'
          value: new UntypedFormControl(this.issuesValues.join(', '), [
            Validators.required,
          ]), // Pre-fill with existing values
        }),
      },
      fields: [
        {
          field: 'text-field',
          controlName: 'property',
          placeholder: 'Property Name',
          label: 'Property',
        },
        {
          field: 'text-fields',
          controlName: 'value',
          placeholder: 'Enter issue values',
          label: 'Issue Value(s)',
        },
      ],
      primaryBtn: 'Save',
    };

    const dialogRef = this.dialog.open(ConfirmationPopupWithFormComponent, {
      panelClass: 'center-dialog-box',
      backdropClass: 'backdrop',
      disableClose: true,
      width: '50%',
      data: formData,
    });

    dialogRef.closed.subscribe((result: any) => {
      if (result) {
        this.issuesValues = Array.isArray(result.value)
          ? result.value
          : [result.value];
      }
    });
  }

  // getNextSequenceForOthers(): number {
  //   // Find the maximum sequence number in the "Others" category from groupedProperties
  //   const maxGroupedSequence = this.groupedProperties.flatMap(group =>
  //       group.properties
  //           .filter(prop => prop.category === 'Others')
  //           .map(prop => prop.sequence || 0)
  //   ).reduce((max, sequence) => Math.max(max, sequence), 0);
  //    // Calculate the maximum sequence number in the "Others" category from properties
  //   const maxPropertiesSequence = this.properties
  //       .map((prop, index) => index + 1) // Use index + 1 as a fallback sequence
  //       .reduce((max, sequence) => Math.max(max, sequence), 0);
  //    // Return the next available sequence number
  //   return Math.max(maxGroupedSequence, maxPropertiesSequence) + 1;
  // }

  getNextSequenceForOthers(): number {
    // Find the maximum sequence number in the "Others" category from groupedProperties
    const maxGroupedSequence = this.groupedProperties
      .flatMap((group) =>
        group.properties
          .filter((prop) => prop?.category?.toLowerCase() === 'others') // Ensure case-insensitivity
          .map((prop) => prop.sequence || 0)
      )
      .reduce((max, sequence) => Math.max(max, sequence), 0);

    // Calculate the maximum sequence number in the "Others" category from properties
    const maxPropertiesSequence = this.properties
      .map((prop, index) => index + 1) // Use index + 1 as a fallback sequence
      .reduce((max, sequence) => Math.max(max, sequence), 0);

    // Return the next available sequence number
    return Math.max(maxGroupedSequence, maxPropertiesSequence) + 1;
  }
  onSubmit() {
    if (this.assetTypeForm.valid) {
      const formData = this.assetTypeForm.value;
      const nextSequenceForOthers = this.getNextSequenceForOthers();
      // Construct the categories array
      const categoriesMap = new Map();
      this.groupedProperties.forEach((group, index) => {
        if (!categoriesMap.has(group.groupName)) {
          categoriesMap.set(group.groupName, {
            name: group.groupName,
            sequence: index + 1,
          });
        }
      });

      if (this.properties.length > 0 && !categoriesMap.has('Others')) {
        categoriesMap.set('Others', {
          name: 'Others',
          sequence: categoriesMap.size + 1,
        });
      }

      if (!categoriesMap.has('Location')) {
        categoriesMap.set('Location', {
          name: 'Location',
          sequence: categoriesMap.size + 1,
        });
      }

      // Ensure "Purchase Details" category is added
      if (!categoriesMap.has('Purchase Details')) {
        categoriesMap.set('Purchase Details', {
          name: 'Purchase Details',
          sequence: categoriesMap.size + 1,
        });
      }

      const categories = Array.from(categoriesMap.values());

      // Construct configs
      const configs = [
        ...this.groupedProperties.flatMap((group) =>
          group.properties.map((prop, index) => ({
            name: prop.property || prop.displayName,
            values: prop.values || prop.value,
            valueType: prop.valueType || 'string',
            isRequired: prop.isRequired,
            sequence: index + 1,
            category: group.groupName,
            units: prop.units || prop.unit,
          }))
        ),

        ...this.properties.map((prop, index) => ({
          name: prop.property,
          values: prop.value,
          valueType: 'string',
          isRequired: prop.isRequired,
          sequence: nextSequenceForOthers + 1,
          category: 'Others',
          units: prop.unit,
        })),
        ...this.purchaseDetailsToConfigs(),
      ];

      // Add Location config
      configs.push({
        name: 'Location',
        values: this.locations?.map(
          (location: { attributeValue: any }) => location.attributeValue
        ),
        valueType: 'string',
        isRequired: true,
        sequence: configs.length + 1,
        category: 'Location',
        units: [],
      });

      // Apply filter to remove duplicates
      const finalValid = this.filterRedundantSequences(configs);

      const payload = {
        name: formData.name,
        assetTypeCode: formData.assetTypeCode,
        categories,
        configs: finalValid,
      };

      // Debug: Check payload before submission

      this.commonHttpService
        .submitAssetType(formData.id, payload)
        .subscribe((response) => {
          this.formSubmitted.emit();
          this.router.navigate([ROUTES.MANAGE_WORKSPACE_OWNER]);
        });
    }
  }

  filterRedundantSequences(data: any[]): any[] {
    const uniqueConfigs = new Map();

    data.forEach((item) => {
      // Normalize the name to ensure consistency
      const normalizedName = item.name.replace(/\s+/g, '').toLowerCase();
      const key = `${normalizedName}-${item.category.toLowerCase()}`;

      if (!uniqueConfigs.has(key)) {
        uniqueConfigs.set(key, item);
      } else {
        // If a duplicate is found, prefer the one with non-empty values
        const existingItem = uniqueConfigs.get(key);
        if (item?.values.length > 0 && existingItem.values.length === 0) {
          uniqueConfigs.set(key, item);
        }
      }
    });

    return Array.from(uniqueConfigs.values());
  }
  purchaseDetailsToConfigs() {
    return [
      {
        name: 'Purchase Date',
        values: this.purchaseDateValues,
        valueType: 'string',
        isRequired: true,
        sequence: 1,
        category: 'Purchase Details',
        units: this.purchaseDateUnits,
      },
      {
        name: 'Purchase Cost',
        values: this.purchaseCostValues,
        valueType: 'string',
        isRequired: true,
        sequence: 2,
        category: 'Purchase Details',
        units: this.purchaseCostUnits,
      },
      {
        name: 'Warranty',
        values: this.warrantyValues,
        valueType: 'string',
        isRequired: true,
        sequence: 3,
        category: 'Purchase Details',
        units: this.warrantyUnits,
      },
      {
        name: 'Invoice Number',
        values: this.invoiceNumberValues,
        valueType: 'string',
        isRequired: true,
        sequence: 4,
        category: 'Purchase Details',
        units: this.invoiceNumberUnits,
      },
    ];
  }

  addPropertyToGroup(groupName: string): void {
    const formData = {
      header: 'Add Property to Group',
      description: 'Add a property to the group',
      image: 'cautionImage',
      form: {
        confirmationForm: new FormGroup({
          groupName: new FormControl(groupName, [Validators.required]),
          property: new FormControl('', [Validators.required]),
          value: new FormControl(''),
          unit: new FormControl(''),
        }),
      },
      fields: [
        {
          field: 'text-field',
          controlName: 'property',
          placeholder: 'E.g., RAM size',
          label: 'Property',
        },
        {
          field: 'text-fields',
          controlName: 'value',
          placeholder: 'E.g., 4, 8, 16 etc.',
          label: 'Value(s)',
        },
      ],
      primaryBtn: 'Add',
    };

    const dialogRef = this.dialog.open(ConfirmationPopupWithFormComponent, {
      panelClass: 'center-dialog-box',
      backdropClass: 'backdrop',
      disableClose: true,
      width: '50%',
      data: formData,
    });

    dialogRef.closed.subscribe((result: any) => {
      if (result) {
        // Add property to the group
        const group = this.groupedProperties.find(
          (g) => g.groupName === groupName
        );
        if (group) {
          group.properties.push({
            property: result.property,
            value: Array.isArray(result.value) ? result.value : [result.value],
            unit: Array.isArray(result.unit) ? result.unit : [result.unit],
            isRequired: result.isRequired,
            checked: false,
          });
        }
      }
    });
  }

  renameGroup(groupName: string): void {
    const formData = {
      header: 'Rename Group',
      description: 'Enter a new name for the group',
      image: 'cautionImage',
      form: {
        confirmationForm: new FormGroup({
          groupName: new FormControl(groupName, [Validators.required]),
        }),
      },
      fields: [
        {
          field: 'text-field',
          controlName: 'groupName',
          placeholder: 'Enter new group name',
          label: 'Group Name',
        },
      ],
      primaryBtn: 'Save',
    };

    const dialogRef = this.dialog.open(ConfirmationPopupWithFormComponent, {
      panelClass: 'center-dialog-box',
      backdropClass: 'backdrop',
      disableClose: true,
      width: '50%',
      data: formData,
    });

    dialogRef.closed.subscribe((result: any) => {
      if (result) {
        // Rename the group
        const group = this.groupedProperties.find(
          (g) => g.groupName === groupName
        );
        if (group) {
          group.groupName = result.groupName;
        }
      }
    });
  }
}
