import { NgModule, isDevMode } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AuthModule } from 'libs/shared/web/auth/auth.module';
import { FormsModule } from 'libs/shared/web/forms/forms.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TokenInterceptorService } from '@shared/token-interceptor';
// import { AdminModule } from 'libs/shared/web/admin/admin.module';
import { CommonModule } from '@angular/common';
import { AdminModule } from 'libs/shared/web/dashboard/admin/admin.module';
import { DashboardRoutingModule } from 'libs/shared/web/dashboard/dashboard-routing.module';
import { DashboardModule } from 'libs/shared/web/dashboard/dashboard.module';
import { EmployeeModule } from 'libs/shared/web/dashboard/employee/employee.module';
import { StoreModule } from '@ngrx/store';
import { reducers } from './app.state';
import { STORE_RUNTIME_CHECKS } from '@common/interfaces';
import { searchStateReducer } from 'store/search-state/search.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment';
import { WorkspaceOwnerModule } from 'libs/shared/web/dashboard/workspace-owner/workspace-owner.module';
// import { UserModule } from 'libs/shared/web/user/user.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    RouterModule,
    AuthModule,
    FormsModule,
    HttpClientModule,
    DashboardRoutingModule,
    DashboardModule,
    CommonModule,
    EmployeeModule,WorkspaceOwnerModule,
    AdminModule,
    BrowserAnimationsModule,
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        ...STORE_RUNTIME_CHECKS
      }
    }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    StoreModule.forFeature(
      searchStateReducer.searchFeatureKey,
      searchStateReducer.searchReducer
    )
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
