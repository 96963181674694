<div class="wrapper">
  <app-generic-drop-down
    label="Choose"
    (value)="changeIndex($event)"
    [options]="searchOptions"
    titleName="title"
    valueName="value"
    [title]="activeCol[0]?.title || searchOptions[0].title"
    class="drop-down-container"
    [shouldEmitEmittedValue]="true"
  ></app-generic-drop-down>
  <div class="search-input-container">
    <div class="input-field">
      <img src="assets/18x18/search.svg" alt="" class="search-icon"/>
      <input
        class="text-field"
        [(ngModel)]="searchStr"
        placeholder="Search by project name"
      />
      <img
        class="icon"
        src="assets/20x20/close.svg"
        [ngClass]="{ hide: !searchStr, show: searchStr }"
        alt="Remove Icon"
        (click)="removeClick()"
      />
    </div>
  </div>

  <app-button
    size="xsmall"
    theme="secondary"
    class="btn-wrapper"
    (click)="search()"
    type="submit"
    [disabled]="!searchStr"
  >
    Search
  </app-button>
</div>
