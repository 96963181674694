import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { IFileInfo, PROGRESS_STATUS } from '@common/interfaces';
import { CommonHttpService } from 'libs/common/src/lib/service/common-http.service';
import { CommonService } from 'libs/common/src/lib/service/common.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-confirmation-popup-with-form',
  templateUrl: './confirmation-popup-with-form.component.html',
  styleUrls: ['./confirmation-popup-with-form.component.scss'],
})
export class ConfirmationPopupWithFormComponent implements OnInit {
  fileTypes = [
    'application/pdf',
    'image/png',
    'image/jpg',
    'image/svg+xml',
    'image/jpeg',
  ];

  header = '';
  description = '';
  primaryBtn = '';
  secondaryBtn!: string;
  image = '';
  imageAddress = '';
  successImage = 'assets/48x48/Featured iconGreen-tick.svg';
  failureImage = 'assets/48x48/Featured iconred-exclamatory.svg';
  cautionImage = 'assets/48x48/logoutyellow-excalmatory.svg';
  confirmationForm!: FormGroup;
  fields: any[] = [];
  uploadInProgress: boolean = false;
  fileKeys: string[] = [];
  value: string = '';
  valuesArray: FormArray<FormControl>;
  unitsArray: FormArray<FormControl>;
  constructor(
    private commonHttpService: CommonHttpService,
    private commonService: CommonService,
    private dialogRef: DialogRef,
    @Inject(FormBuilder) private fb: FormBuilder,
    @Inject(DIALOG_DATA)
    public data: {
      header: string;
      description: string;
      image: string;
      form: { confirmationForm: FormGroup };
      fields: any[];
      secondaryBtn: string;
      primaryBtn: string;
      isEditMode?:boolean;
    }
  ) {
    // this.confirmationForm = this.fb.group({
    //   value: this.fb.array([this.fb.control('')]),
    //   unit: this.fb.array([this.fb.control('')]),
    // });
    this.valuesArray = this.fb.array<FormControl>([
      this.fb.control('', Validators.required),
    ]);
    this.unitsArray = this.fb.array<FormControl>([
      this.fb.control('', Validators.required),
    ]);
  }
  ngOnInit(): void {
    this.header = this.data.header;
    this.description = this.data.description;
    this.image = this.data.image;
    this.imageAddress = this.getImageAddress(this.image);
    this.confirmationForm = this.data.form.confirmationForm;
    this.fields = this.data.fields;
    this.secondaryBtn = this.data.secondaryBtn;
    this.primaryBtn = this.data.primaryBtn;

    // Check if 'text-fields' are present
    const hasTextFields = this.fields.some(
      (field) => field.field === 'text-fields'
    );

    if (hasTextFields) {
      const initialValues = (this.confirmationForm.get('value')?.value || '')
        .split(',')
        .map((v: string) => v.trim());
      const initialUnits = (this.confirmationForm.get('unit')?.value || '')
        .split(',')
        .map((u: string) => u.trim());

      this.valuesArray = this.fb.array(
        initialValues.map((val: string) =>
          this.fb.control(val, Validators.required)
        )
      );
      this.unitsArray = this.fb.array(
        initialUnits.map((unit: string) =>
          this.fb.control(unit, Validators.required)
        )
      );

      // this.confirmationForm.setControl('valuesArray', this.valuesArray);
      // this.confirmationForm.setControl('unitsArray', this.unitsArray);
      this.confirmationForm.addControl('isRequired', new FormControl(false));
    }

    this.confirmationForm.statusChanges.subscribe((status) => {});

    // Listen for changes in arrays
    this.syncFormControls();
  }
  get isAddButtonEnabled(): boolean {
    return this.valuesArray.controls.some(
      (control) => control.value.trim() !== ''
    );
  }
  get isUnitButtonEnabled(): boolean {
    return this.unitsArray.controls.some(
      (control) => control.value.trim() !== ''
    );
  }
  getImageAddress(imageType: string): string {
    switch (imageType) {
      case 'successImage':
        return this.successImage;
      case 'failureImage':
        return this.failureImage;
      case 'cautionImage':
        return this.cautionImage;
      default:
        return '';
    }
  }

  syncFormControls() {
    this.valuesArray.valueChanges.subscribe((values) => {
      const concatenatedValues = values.join(', '); // Combine all values as a single string
      this.confirmationForm
        .get('value')
        ?.setValue(concatenatedValues, { emitEvent: false });
    });

    this.unitsArray.valueChanges.subscribe((units) => {
      const concatenatedUnits = units.join(', '); // Combine all units as a single string
      this.confirmationForm
        .get('unit')
        ?.setValue(concatenatedUnits, { emitEvent: false });
    });
  }

  onClick(buttonText: string) {
    if (buttonText === 'Cancel') {
      this.closeDialog();
    } else {
      if (this.fileKeys.length > 0)
        this.getOption(this.fileKeys, 'ticketClosureTempFileKey');
      this.dialogRef.close(this.confirmationForm.value);
    }
  }
  getOption(option: string | string[], control: string) {
    this.confirmationForm.controls[control].setValue(option);
  }
  getFormArray(controlName: string): FormArray {
    return this.confirmationForm.get(controlName) as FormArray;
  }

  addControl(controlName: string): void {
    if (controlName === 'value') {
      const newControl = this.fb.control('', Validators.required);
      this.valuesArray.push(newControl);
    } else if (controlName === 'unit') {
      const newControl = this.fb.control('', Validators.required);
      this.unitsArray.push(newControl);
    }
  }
  removeValue(index: number): void {
    this.valuesArray.removeAt(index);
  }

  removeUnit(index: number): void {
    this.unitsArray.removeAt(index);
  }
  closeDialog() {
    this.dialogRef.close();
  }

  async selectedFiles(files: IFileInfo[]) {
    if (files.length) {
      for (const fileData of files) {
        await this.uploadFile([fileData]);
      }
    }
  }

  async uploadFile(files: IFileInfo[]) {
    this.uploadInProgress = true;

    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      if (this.shouldUploadFile(file) && !this.isAlreadyUploaded(file)) {
        await this.uploadAndHandleFile(file);
      } else {
        files[index].showProgressBar = false;
      }
    }
  }

  shouldUploadFile(file: IFileInfo): boolean {
    return (
      file.progressStatus !== PROGRESS_STATUS.FAILED &&
      file.progressValue !== 100
    );
  }

  isAlreadyUploaded(file: IFileInfo): boolean {
    return file.progressValue === 100;
  }

  async uploadAndHandleFile(file: IFileInfo) {
    this.uploadInProgress = true;

    const subscription = this.commonService.getProgress().subscribe((value) => {
      file.progressValue = value;
      file.showRemoveFileBtn = false;
    });

    try {
      const fileInfo = [
        {
          originalname: file.originalname,
          mimetype: file.mimetype,
          size: file.size,
        },
      ];
      const loadRequest: Observable<Object> =
        this.commonHttpService.uploadFiles(fileInfo);
      this.uploadInProgress = false;
      subscription?.unsubscribe();
      loadRequest.subscribe({
        next: async (response: any) => {
          if (this.shouldUploadFile(file) && !this.isAlreadyUploaded(file)) {
            file.progressValue = 50;
            const s3response = await this.handleFileUpload(
              response.presignedUrls,
              [file]
            );

            if (response.presignedUrls.length === s3response.length) {
              file.progressValue = 100;
              file.showRemoveFileBtn = false;
              file.progressStatus = PROGRESS_STATUS.SUCCESS;

              const tempFileKeys = response.presignedUrls[0].key;
              this.fileKeys.push(tempFileKeys);
            }
          }
        },
        error: (error) => {
          file.showRemoveFileBtn = true;
          file.progressStatus = PROGRESS_STATUS.FAILED;
        },
      });
    } catch (error) {
      console.error(error);
    }
  }

  handleFileUpload(
    preSignedUrls: { key: string; presignedUrl: string }[],
    files: IFileInfo[]
  ) {
    return this.commonHttpService.uploadFilesToPresignedUrls(
      preSignedUrls,
      files
    );
  }
}
