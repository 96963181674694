<div *ngIf=" assets && !assets.name">
  <div class="asset" *ngIf="assets && assets.assetTypeName else noAssetsFound">
    <div class="asset-content">
      <p class="asset-name">
        <span *ngIf="assets.assetTypeName.substring(0,3) === 'Eko' "
          ><img src="assets/eko-images/eko_logo.svg" alt="eko-logo" />
        </span>
        <span>{{assets.assetTypeName || assets.name}}</span>
      </p>
      <p class="asset-description">{{assets.modelName}}</p>
      <p class="asset-modal">{{assets.assetCode}}</p>
    </div>
    <div
      class="asset-image"
      *ngIf="assets.assetTypeName.substring(0,3)!=='Eko'"
    >
      <img
        src="assets/80x80/{{assets?.assetTypeName?.toLowerCase()}}.svg"
        alt="asset-icon"
        height="80"
      />
    </div>
    <div
      class="asset-image"
      *ngIf="assets.assetTypeName.substring(0,3)==='Eko'"
    >
      <img src="assets/80x80/eko device cr2.svg" alt="asset-icon" height="80" />
    </div>
  </div>
</div>

<!-- workspace assets -->
<!-- asset-types -->
<div *ngIf=" assets && !assets.assetTypeName">
  <div class="asset" *ngIf="assets && assets.name else noAssetsTypeFound">
    <div class="asset-content">
      <p class="asset-name">
        <span>{{assets.name}}</span>
      </p>
      <p class="asset-description">{{assets.properties.length }} Properties</p>
    </div>
    <div class="asset-image" *ngIf="assets.name.substring(0,3)!=='Eko'">
      <img
        src="assets/80x80/{{assets?.name?.toLowerCase()}}.svg  "
        alt="asset-icon"
        height="80"
        onerror="this.onerror=null; this.src='assets/144x144/asset-default.svg';"
      />

    </div>
    <div class="asset-image" *ngIf="assets.name.substring(0,3)==='Eko'">
      <img src="assets/80x80/eko device cr2.svg" alt="asset-icon" height="80" />
    </div>
  </div>
</div>

<!-- accessory type -->

<div
  *ngIf=" accessory && accessory.name
"
>
  <div
    class="asset"
    *ngIf="accessory && accessory.name else noAccessoryTypeFound"
  >
    <div class="asset-content">
      <p class="asset-name">
        <span>{{accessory.name}}</span>
      </p>
      <p class="asset-description">
        {{accessory.properties.length }} Properties
      </p>
    </div>
    <div class="asset-image" *ngIf="accessory.name.substring(0,3)!=='Eko'">
      <img
        src="assets/80x80/{{accessory?.name?.toLowerCase()}}.svg"
        alt="accessory-icon"
        height="80"
        onerror="this.onerror=null; this.src='assets/144x144/accessory-default.svg';"
      />
    </div>
    <div class="asset-image" *ngIf="accessory.name.substring(0,3)==='Eko'">
      <img src="assets/80x80/eko device cr2.svg" alt="accessory-icon" height="80" />
    </div>
  </div>
</div>
<ng-template #noAssetsFound>
  <div class="asset no-asset">
    <div class="asset-content">
      <p class="asset-name">No assets assigned</p>
      <p class="asset-description">Generate ticket to request an asset</p>
    </div>
  </div>
</ng-template>

<ng-template #noAssetsTypeFound>
  <div class="asset no-asset">
    <div class="asset-content">
      <p class="asset-name">No assets assigned</p>
      <p class="asset-description">Generate ticket to request an asset</p>
    </div>
  </div>
</ng-template>

<ng-template #noAccessoryTypeFound>
  <div class="asset no-asset">
    <div class="asset-content">
      <p class="asset-name">No accessory assigned</p>
      <p class="asset-description">Generate ticket to request an accessory</p>
    </div>
  </div>
</ng-template>
