import { animate, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { debounceTime, filter, Subject } from 'rxjs';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { IworkspaceOwnerDetails } from 'libs/common/src/lib/interfaces/interface';
import { CommonHttpService } from 'libs/common/src/lib/service/common-http.service';
import { CommonService } from 'libs/common/src/lib/service/common.service';
import { paginationChange } from '@common/interfaces';
import { PaginationComponent } from 'libs/shared/ui-components/src/lib/pagination/pagination.component';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ConfirmationPopupWithFormComponent } from 'libs/shared/ui-components/src/lib/confirmation-popup-with-form/confirmation-popup-with-form.component';
import { Dialog } from '@angular/cdk/dialog';
import { ToastrService } from 'ngx-toastr';
import { AddAssetTypeComponent } from '../add-asset-type/add-asset-type.component';
import { AssetTypeDetailComponent } from '../asset-type-detail/asset-type-detail.component';
import { AccessoryTypeDetailComponent } from '../accessory-type-detail/accessory-type-detail.component';
import { AddAccessoryTypeComponent } from '../add-accessory-type/add-accessory-type.component';
@Component({
  selector: 'app-manage-workspace-owner',
  templateUrl: './manage-workspace-owner.component.html',
  styleUrls: ['./manage-workspace-owner.component.scss'],
  animations: [
    trigger('basicAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [animate('100ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class ManageWorkspaceOwnerComponent implements OnInit {
  workspaceId: string = '';
  placeholderTitle = 'No assets assigned';
  placeholderSubtitle = '';
  searchColumn!: string;
  searchText!: string;
  pageSize = '5';
  workspaceProjectDetails: any;
  status: string = '';
  displayedColumns: string[] = [
    'projects',
    'assetsLinked',
    'createdBy',
    'createdOn',
    'edit',
  ];
  displayedColumnsCount = {
    projects: 0,
    assetsLinked: 0,
  };
  editButtonImage: string = 'assets/14x14/Iconedit-button.svg';
  showAssetDetails:boolean = false;
  @ViewChild('manage workspace') _workspaceForPagination!: PaginationComponent;
  @Output() paginatorEmitter = new EventEmitter<any>();
  @ViewChild(AddAssetTypeComponent) addAssetTypeComponent!: AddAssetTypeComponent;
  @ViewChild(AddAccessoryTypeComponent) addAccessoryTypeComponent!: AddAccessoryTypeComponent;
  dataSource: any[] = [];
  count = 0; // Total number of records
  pageCount = '5'; // Default page size
  reload = new EventEmitter<boolean>();
  pageSizeOptions = ['5', '10', '15', '20'];
  selectedPageSize: any;
  workspaceOwnerDetails!: IworkspaceOwnerDetails;
  showAddAssetType = false;
  showAddAccessoryType = false;
  userTabs = [
    { label: 'Workspace Owners', value: 'WORKSPACE-OWNER', count: 0 },
    { label: 'IT Admins', value: 'ADMIN', count: 0 },
    { label: 'End Users', value: 'USER', count: 0 },
  ];
  selectedUserTab = 'WORKSPACE-OWNER';
  userDisplayedColumns: string[] = [
    'name',
    'employeeId',
    'designation',
    'reportingManager',
  ];
  userSearchOptions = [
    { title: 'Name', value: 'NAME' },
    { title: 'Employee ID', value: 'EMP_ID' },
    { title: 'Email', value: 'EMAIL_ID' },
    // { title: 'Designation', value: 'DESIGNATION' }
  ];
  usersDataSource: any[] = [];
  userCount = 0;
  userPageCount = '5';
  userReload = new EventEmitter<boolean>();
  isSearching: boolean = false;
  loaderView: boolean = false;
  assetTypeId!: string;
  assetDetails: any;
  private searchSubject = new Subject<any>();
  constructor(
    private commonHttpService: CommonHttpService,
    private commonService: CommonService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private cdr: ChangeDetectorRef,
    private dialog: Dialog
  ) {
    this.selectedPageSize = this.pageSizeOptions[0];
  }
  ngOnInit(): void {

    this.searchSubject.pipe(debounceTime(900)).subscribe((searchData) => {
      this.getWorkspaceOwnersProjects({
        pageNumber: '1',
        itemsPerPage: this.pageCount,
        searchColumn: searchData.searchBy,
        searchText: searchData.searchText,
      });
    });

    this.workspaceId = localStorage.getItem('workspaceId') as string;
    this.getWorkspaceOwnerDetails(this.workspaceId);
    this.getWorkspaceOwnersProjects(this.workspaceId);

    // Update tab counts from workspace details
    this.updateTabCounts();

    // Get page size from session storage if available
    const pageCount = sessionStorage.getItem('pageSize');
    if (pageCount) {
      this.pageCount = pageCount;
    }

    this.getUsersList();
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.checkCurrentRoute();
      this.getWorkspaceOwnerDetails(this.workspaceId);

    });

    // Initial check
    this.checkCurrentRoute();

    this.activatedRoute.paramMap.subscribe(params => {
      this.assetTypeId = params.get('id') || '';
    });
  }

  checkCurrentRoute() {
    const currentRoute = this.activatedRoute.snapshot.firstChild;
    this.showAddAssetType = currentRoute?.routeConfig?.path === 'add-asset-type';
    this.showAddAccessoryType = currentRoute?.routeConfig?.path === 'add-accessory-type';
  }
  onPageSizeChange(event: any): void {
    if (!this.isSearching) {
      this.pageCount = event.value;
      sessionStorage.setItem('pageSize', event.value);
      this.getWorkspaceOwnersProjects({
        pageNumber: '1',
        itemsPerPage: event.value,
      });
    }
  }
  pageSizeEmitter(index: number): void {
    if (!this.isSearching) {
      paginationChange.next(true);
      this.pageCount = this.pageSizeOptions[index];
      sessionStorage.setItem('pageSize', this.pageCount);
      this.getWorkspaceOwnersProjects({
        pageNumber: '1',
        itemsPerPage: this.pageCount,
      });
    }
  }
  paginationEmitter(params: any) {
    if (!this.isSearching) {
      paginationChange.next(true);
      this.getWorkspaceOwnersProjects(params);
    }
  }

  getWorkspaceOwnerDetails(workspaceId: any) {
    this.loaderView = true;
    this.commonHttpService.getWorkspaceOwnnerDetails(workspaceId).subscribe({
      next: (response: any) => {
        this.workspaceOwnerDetails = Object(response).data;
        this.displayedColumnsCount.assetsLinked =
          this.workspaceOwnerDetails.assetCount;

        this.displayedColumnsCount.projects =
          this.workspaceOwnerDetails.projectCount;

        this.updateTabCounts();
        this.loaderView = false;
      },
      error: (error: any) => {
        this.loaderView = false;
      },
    });
  }

  getWorkspaceOwnersProjects(param?: any) {
    const params = {
      workspaceId: this.workspaceId || '',
      searchColumn: param?.searchColumn || '',
      searchText: param?.searchText || '',
      workSpaceStatus: this.status,
      pageNumber: param?.pageNumber || '1',
      itemsPerPage: param?.itemsPerPage || this.pageCount || '5',
      sortColumn: param?.sortColumn,
      sortDirection: param?.sortDirection,
    };

    this.commonHttpService.getOwnersProjects(params).subscribe({
      next: (response: any) => {
        if (response?.data?.projects) {
          this.dataSource = response.data.projects.map((project: any) => ({
            id: project.id, // Ensure ID is included
            name: project.name, // Ensure name is included
            projects: 1,
            assetLinked: project.assetCount,
            createdBy: project.createdByUser?.name || 'N/A',
            createdOn: new Date(project.createdAt).toLocaleDateString(),
          }));
          this.count = response.data.count || response.data.projects.length;
          this.cdr.detectChanges();
          this.isSearching = false;
        } else {
          this.dataSource = [];
          this.count = 0;
          this.cdr.detectChanges();
        }
      },
      error: (error: any) => {
        this.isSearching = false;
      },
    });
  }

  onTabChange(tabValue: string) {
    if (!this.isSearching) {
      this.selectedUserTab = tabValue;
      this.userPageCount = '5'; // Reset page size
      this.getUsersList({
        pageNumber: '1',
        itemsPerPage: this.userPageCount,
        roleId: this.selectedUserTab,
      });
    }
  }

  getUsersList(param?: any) {
    const params = {
      workspaceId: this.workspaceId,
      pageNumber: param?.pageNumber || '1',
      itemsPerPage: param?.itemsPerPage || this.userPageCount,
      sortColumn: param?.sortColumn,
      sortDirection: param?.sortDirection,
      searchColumn: param?.searchColumn,
      searchText: param?.searchText,
      roleId: this.selectedUserTab, // Ensure roleId is set to selectedUserTab
    };
    this.commonHttpService.getWorkspaceUsers(params).subscribe({
      next: (response: any) => {
        if (response?.data?.rows) {
          this.usersDataSource = response.data.rows.map((user: any) => ({
            name: user.firstName + ' ' + user.lastName,
            employeeId: user.employeeId,
            designation: user.employeeDesignation?.attributeValue || 'N/A',
            email: user.email,
            reportingManager:
              user.reportingManagerDetails?.firstName +
                ' ' +
                user.reportingManagerDetails?.lastName || 'N/A',
            profileImage: user.profilePictureUrl,
          }));
          this.userCount = response.data.count;
          this.cdr.detectChanges();
        }
        this.isSearching = false; // Reset the flag
      },
      error: (error: any) => {
        this.isSearching = false; // Reset the flag on error
      },
    });
  }
  userPageSizeEmitter(index: number): void {
    if (!this.isSearching) {
      paginationChange.next(true);
      this.userPageCount = this.pageSizeOptions[index];
      sessionStorage.setItem('userPageSize', this.userPageCount);
      this.getUsersList({
        pageNumber: '1',
        itemsPerPage: this.userPageCount,
        roleId: this.selectedUserTab,
      });
    }
  }
  userPaginationEmitter(params: any) {
    if (!this.isSearching) {
      paginationChange.next(true);
      this.getUsersList({
        ...params,
        roleId: this.selectedUserTab,
      });
    }
  }

  // Add method to update tab counts
  updateTabCounts() {
    if (this.workspaceOwnerDetails) {
      this.userTabs = this.userTabs.map((tab) => {
        let count = 0;
        switch (tab.value) {
          case 'WORKSPACE-OWNER':
            count = this.workspaceOwnerDetails.workspaceOwnerCount || 0;
            break;
          case 'ADMIN':
            count = this.workspaceOwnerDetails.adminCount || 0;
            break;
          case 'USER':
            count = this.workspaceOwnerDetails.userCount || 0;
            break;
        }
        return { ...tab, count };
      });
      this.cdr.detectChanges();
    }
  }
  onSearch(searchData: any) {
    this.isSearching = true;
    this.getWorkspaceOwnersProjects({
      pageNumber: '1',
      itemsPerPage: this.pageCount,
      searchColumn: searchData.searchBy,
      searchText: searchData.searchText,
    });
  }
  onUserSearch(searchData: any) {
    this.isSearching = true;
    this.getUsersList({
      pageNumber: '1',
      itemsPerPage: this.userPageCount,
      searchColumn: searchData.searchBy,
      searchText: searchData.searchText,
      roleId: this.selectedUserTab, // Send the users tab value as roleId
    });
  }

  onAddProject() {
    const formData = {
      header: 'Add Project Name',
      description: 'Please enter the project name',
      image: 'cautionImage',
      form: {
        confirmationForm: new UntypedFormGroup({
          addProject: new UntypedFormControl('', [Validators.required]),
        }),
      },
      fields: [
        {
          field: 'text-field',
          controlName: 'addProject',
          placeholder: 'Enter Project Name',
          label: 'Project Name',
        },
      ],
      secondaryBtn: 'Cancel',
      primaryBtn: 'Add',
    };

    const dialogRef = this.dialog.open(ConfirmationPopupWithFormComponent, {
      panelClass: 'center-dialog-box',
      backdropClass: 'backdrop',
      disableClose: true,
      width: '50%',
      data: formData,
    });

    dialogRef.closed.subscribe((result) => {
      if (result) {
        const projectData = {
          name: Object(result).addProject,
          isDefault: false,
        };

        this.commonHttpService.addWorkspaceProject(projectData).subscribe({
          next: (response: any) => {
            if (response?.statusCode === 200) {
              this.toastrService.success(
                `Project '${projectData.name}' is successfully added`,
                '',
                {
                  toastClass: 'toaster-wrapper',
                }
              );
              // Refresh the project list or perform any additional actions
            }
          },
          error: (error) => {
            const errorMsg =
              error?.error?.error?.errorList[0]?.msg || 'Something went wrong!';
            this.toastrService.error(errorMsg, '', {
              toastClass: 'toaster-wrapper',
            });
          },
        });
      }
    });
  }
  onEditProject(project: any) {
    const formData = {
      header: 'Edit Project Name',
      description: 'Please update the project name',
      image: 'cautionImage',
      form: {
        confirmationForm: new UntypedFormGroup({
          editProject: new UntypedFormControl(project.name || '', [
            Validators.required,
          ]),
        }),
      },
      fields: [
        {
          field: 'text-field',
          controlName: 'editProject',
          placeholder: 'Enter Project Name',
          label: 'Project Name',
        },
      ],
      secondaryBtn: 'Cancel',
      primaryBtn: 'Update',
    };

    const dialogRef = this.dialog.open(ConfirmationPopupWithFormComponent, {
      panelClass: 'center-dialog-box',
      backdropClass: 'backdrop',
      disableClose: true,
      width: '50%',
      data: formData,
    });

    dialogRef.closed.subscribe((result) => {
      if (result) {
        const updatedProjectData = {
          name: Object(result).editProject,
        };

        this.commonHttpService
          .updateWorkspaceProject(project.id, updatedProjectData)
          .subscribe({
            next: (response: any) => {
              if (response.statusCode === 200) {
                this.toastrService.success(
                  `Project '${updatedProjectData.name}' is successfully saved`,
                  '',
                  {
                    toastClass: 'toaster-wrapper',
                  }
                );
                this.getWorkspaceOwnersProjects();
                // Refresh the project list or perform any additional actions
              }
            },
            error: (error) => {
              const errorMsg = error?.message || 'Something went wrong!';
              this.toastrService.error(errorMsg, '', {
                toastClass: 'toaster-wrapper',
              });
            },
          });
      }
    });
  }

  onAddAssetType(): void {
    const formData = {
      header: 'Add Asset Type',
      description: 'Please enter the details to proceed',
      image: 'cautionImage',
      form: {
        confirmationForm: new UntypedFormGroup({
          name: new UntypedFormControl('', [Validators.required]),
          assetTypeCode: new UntypedFormControl('', [Validators.required]),

        }),
      },
      fields: [
        {
          field: 'text-field',
          controlName: 'name',
          placeholder: 'Enter Asset Type Name',
          label: 'Asset Type Name',
        },
        {
          field: 'text-field',
          controlName: 'assetTypeCode',
          placeholder: 'Enter Asset Type Code',
          label: 'Asset Type Code',
        }

      ],
      secondaryBtn: 'Cancel',
      primaryBtn: 'Add',
    };

    const dialogRef = this.dialog.open(ConfirmationPopupWithFormComponent, {
      panelClass: 'center-dialog-box',
      backdropClass: 'backdrop',
      disableClose: true,
      width: '50%',
      data: formData,
    });

    dialogRef.closed.subscribe((result:any) => {
      if (result) {
        const payload = {
          name: result.name,
          assetTypeCode: result.assetTypeCode,
          icon: null,
        };
        this.commonHttpService.addAssetTypes(payload).subscribe({
          next: (response: any) => {
            // this.toastrService.success(`Asset Type '${response.name}' added successfully`, '', {
            //   toastClass: 'toaster-wrapper',
            // });
            // Redirect to the add asset page with the response data
            this.showAddAssetType = true
            this.router.navigate(['add-asset-type'], {
              relativeTo: this.activatedRoute,
              queryParams: {
                name: response.data.name,
                assetTypeCode: response.data.assetTypeCode,
                id: response.data.id
              },
            });
          },
          error: (error) => {
            const errorMsg = error?.message || 'Something went wrong!';
            this.toastrService.error(errorMsg, '', {
              toastClass: 'toaster-wrapper',
            });
          },
        });
      }
    });
  }

  onAddAccessoryType(): void {
    const formData = {
      header: 'Add Accessory Type',
      description: 'Please enter the details to proceed',
      image: 'cautionImage',
      form: {
        confirmationForm: new UntypedFormGroup({
          name: new UntypedFormControl('', [Validators.required]),
        }),
      },
      fields: [
        {
          field: 'text-field',
          controlName: 'name',
          placeholder: 'Enter Accessory Type Name',
          label: 'Accessory Type Name',
        },
      ],
      secondaryBtn: 'Cancel',
      primaryBtn: 'Add',
    };

    const dialogRef = this.dialog.open(ConfirmationPopupWithFormComponent, {
      panelClass: 'center-dialog-box',
      backdropClass: 'backdrop',
      disableClose: true,
      width: '50%',
      data: formData,
    });

    dialogRef.closed.subscribe((result:any) => {
      if (result) {
        const payload = {
          name: result.name,
          icon: null,
        };
        this.commonHttpService.addAccessoryTypes(payload).subscribe({
          next: (response: any) => {
            // this.toastrService.success(`Accessory Type '${response.name}' added successfully`, '', {
            //   toastClass: 'toaster-wrapper',
            // });
            // Redirect to the add accessory page with the response data
            this.showAddAccessoryType = true;
            this.router.navigate(['add-accessory-type'], {
              relativeTo: this.activatedRoute,
              queryParams: {
                name: response.data.name,
                id: response.data.id
              },
            });
          },
          error: (error) => {
            const errorMsg = error?.message || 'Something went wrong!';
            this.toastrService.error(errorMsg, '', {
              toastClass: 'toaster-wrapper',
            });
          },
        });
      }
    });
  }

  onAddMoreAcronyms(): void {
    const formData = {
      header: 'Add Asset Code Acronym',
      description: 'Please enter the Asset Code Acronym',
      image: 'cautionImage',
      form: {
        confirmationForm: new UntypedFormGroup({
          acronym: new UntypedFormControl('', [Validators.required]),
        }),
      },
      fields: [
        {
          field: 'text-field',
          controlName: 'acronym',
          placeholder: 'Enter Acronym',
          label: 'Acronym',
        },
      ],
      secondaryBtn: 'Cancel',
      primaryBtn: 'Add',
    };

    const dialogRef = this.dialog.open(ConfirmationPopupWithFormComponent, {
      panelClass: 'center-dialog-box',
      backdropClass: 'backdrop',
      disableClose: true,
      width: '50%',
      data: formData,
    });

    dialogRef.closed.subscribe((result: any) => {
      if (result) {
        const acronym = result.acronym;
        const payload = { acronyms: [acronym] };


        this.commonHttpService.addAcronyms(payload).subscribe({
          next: (response: any) => {
            this.toastrService.success(`Acronym '${acronym}' is successfully added!`, '', {
              toastClass: 'toaster-wrapper',
            });

            this.getWorkspaceOwnerDetails(this.workspaceId)
            // Refresh the list or perform additional actions
          },
          error: (error) => {
            const errorMsg = error?.message || 'Something went wrong!';
            this.toastrService.error(errorMsg, '', {
              toastClass: 'toaster-wrapper',
            });
          },
        });
      }
    });
  }

  onAssetTypeClick(assetTypeId: string) {
    //  this.router.navigate(['asset-type', assetTypeId], { relativeTo: this.activatedRoute });
    const dialogRef = this.dialog.open(AssetTypeDetailComponent, {
      panelClass: 'center-dialog-box',
      backdropClass: 'backdrop',
      disableClose: true,
      width: '50%',
      data: { id: assetTypeId }
    });
    dialogRef.closed.subscribe((result: any) => {
      // Handle any actions after the dialog is closed
    });
  }

  onAccessoryTypeClick(assetTypeId: string) {
    //  this.router.navigate(['asset-type', assetTypeId], { relativeTo: this.activatedRoute });
    const dialogRef = this.dialog.open(AccessoryTypeDetailComponent, {
      panelClass: 'center-dialog-box',
      backdropClass: 'backdrop',
      disableClose: true,
      width: '50%',
      data: { id: assetTypeId }
    });
    dialogRef.closed.subscribe((result: any) => {
      // Handle any actions after the dialog is closed
    });
  }
}
