<div class="accessory-team-container">
    <div class="button">
      <app-back-button></app-back-button>
  
      <app-button
        class="spacing"
        type="submit"
        size="medium"
        theme="primary"
        (click)="onSubmit()"
        >Submit
      </app-button>
    </div>
    <div class="wrapper-container">
      <form [formGroup]="accessoryTypeForm">
        <div class="accessory-team-container">
          <h1 class="heading">{{ isEditMode ? 'Edit Accessory Type' : 'Add Accessory Type' }}</h1>
          <div class="frame-1">
            <div class="card-container">
              <div class="accessory-details-card">
                <h2 class="card-title">Accessory Type Details *</h2>
                <div class="details">
                  <div class="field">
                    <span class="label">Name:</span>
                    <span class="value">{{
                      accessoryTypeForm.get("name")?.value
                    }}</span>
                  </div>
                </div>
                <p class="error-message">* Values must be provided</p>
              </div>
            </div>
            <div class="accessory-image">
              <img src="assets/144x144/accessory-default.svg" alt="Accessory" />
            </div>
          </div>
  
          <div class="accessory-type-property-section">
            <div class="accordion">
              <div *ngFor="let group of groupedProperties; let groupIndex = index">
                <div class="accordion-item">
                  <button class="accordion-button" (click)="toggleGroupAccordion(group.groupName)">
                    {{ group.groupName }}
                    <img
                      [src]="groupAccordionState[group.groupName] ? 'assets/10x5/Iconupper-arrow.svg' : 'assets/12x6/Icondropdown-closed.svg'"
                      alt="Toggle"
                      class="accordion-arrow"
                    />
                  </button>
                  <div class="accordion-content" [hidden]="!groupAccordionState[group.groupName]">
                    <div *ngFor="let prop of group.properties; let propertyIndex = index" class="property-item">
                      <div class="property-details">
                        <img src="assets/24x48/sequence.svg" alt="Sequence" />
                        <div class="property-info">
                          <span class="property-name">{{ prop.displayName || prop.name || prop.property }}</span>
                          <span class="property-value">Value(s): {{ prop?.values?.join(', ') || prop?.value?.join(', ') }}</span>
                          <span class="property-unit">Unit(s): {{ prop?.units?.join(', ')|| prop?.unit?.join(', ')  }}</span>
                        </div>
                        <div class="accessory-type-property-edit">
                          <span *ngIf="prop.isRequired" class="required-label">Required</span>
                          <img
                            src="assets/14x14/edit_blue.svg"
                            alt="Edit"
                            class="edit-icon"
                            (click)="onEditProperty(groupIndex, propertyIndex)"
                          />
                          <input
                            type="checkbox"
                            [checked]="prop.checked"
                            (change)="toggleCheckbox(propertyIndex)"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Grouped Properties -->
  
  
            <!-- Ungrouped Properties -->
            <div *ngFor="let prop of properties; let i = index" class="property-item">
              <div class="property-details">
                <img src="assets/24x48/sequence.svg" alt="Sequence" />
                <div class="property-info">
                  <span class="property-name">{{ prop.property }}</span>
                  <span class="property-value">Value(s): {{ prop?.value?.join(', ') }}</span>
                  <span class="property-unit">Unit(s): {{ prop?.unit?.join(', ') }}</span>
                </div>
                <div class="asset-type-property-edit">
                  <span *ngIf="prop.isRequired" class="required-label">Required</span>
                  <img
                    src="assets/14x14/edit_blue.svg"
                    alt="Edit"
                    class="edit-icon"
                    (click)="onEditProperty(null, i)"
                  />
                  <input
                    type="checkbox"
                    [checked]="prop.checked"
                    (change)="toggleCheckbox(i)"
                  />
                </div>
              </div>
            </div>
  
            <!-- Add Property Button -->
            <div class="accessory-type-properties">
              <img src="assets/24x24/plus_circle.svg" alt="" />
              <button (click)="onAddProperty()" class="add-property-button">
                Add Property
              </button>
            </div>
          </div>
  
          <div class="accordion">
            <div class="accordion-item" *ngIf="!isEditMode">
              <button class="accordion-button" (click)="toggleAccordion(1)">
                Purchase Details
                <img
                  [src]="
                    accordionState[1]
                      ? 'assets/10x5/Iconupper-arrow.svg'
                      : 'assets/12x6/Icondropdown-closed.svg'
                  "
                  alt="Toggle"
                  class="accordion-arrow"
                />
              </button>
              <div class="accordion-content" [hidden]="!accordionState[1]">
                <!-- Content for Purchase Details -->
  
                <div class="accordion-field">
                  <div class="accordion-field-header">
                    <span class="label">Purchase Date</span>
                    <img
                      src="assets/14x14/edit_blue.svg"
                      alt=""
                      (click)="onEditDetail('Purchase Date')"
                    />
                  </div>
  
  
                  <div
                  *ngIf="purchaseDateValues.length || purchaseDateUnits.length"
                  >
                    <div *ngFor="let value of purchaseDateValues">
                      Value(s): {{ value }}
                    </div>
                    <div *ngFor="let unit of purchaseDateUnits">
                      Unit(s): {{ unit }}
                    </div>
                  </div>
                </div>
                <div class="accordion-field">
                  <div class="accordion-field-header">
                    <span class="label">Purchase Cost</span>
                    <img src="assets/14x14/edit_blue.svg" alt="" (click)="onEditDetail('Purchase Cost')" />
                  </div>
                  <div
                    *ngIf="purchaseCostValues.length || purchaseCostUnits.length"
                  >
                    <div *ngFor="let value of purchaseCostValues">
                      Value: {{ value }}
                    </div>
                    <div *ngFor="let unit of purchaseCostUnits">
                      Unit: {{ unit }}
                    </div>
                  </div>
                </div>
                <div class="accordion-field">
                  <div class="accordion-field-header">
                    <span class="label">Warranty</span>
                    <img src="assets/14x14/edit_blue.svg" alt=""  (click)="onEditDetail('Warranty')"/>
                  </div>
                  <div *ngIf="warrantyValues.length || warrantyUnits.length">
                    <div *ngFor="let value of warrantyValues">
                      Value: {{ value }}
                    </div>
                    <div *ngFor="let unit of warrantyUnits">Unit: {{ unit }}</div>
                  </div>
                </div>
                <div class="accordion-field">
                  <div class="accordion-field-header">
                    <span class="label">Invoice Number</span>
                    <img src="assets/14x14/edit_blue.svg" alt="" (click)="onEditDetail('Invoice Number')" />
                  </div>
                  <div
                    *ngIf="
                      invoiceNumberValues.length || invoiceNumberUnits.length
                    "
                  >
                    <div *ngFor="let value of invoiceNumberValues">
                      Value: {{ value }}
                    </div>
                    <div *ngFor="let unit of invoiceNumberUnits">
                      Unit: {{ unit }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item" *ngIf="!isEditMode">
              <button class="accordion-button" (click)="toggleAccordion(2)">
                Location
                <img
                  [src]="
                    accordionState[2]
                      ? 'assets/10x5/Iconupper-arrow.svg'
                      : 'assets/12x6/Icondropdown-closed.svg'
                  "
                  alt="Toggle"
                  class="accordion-arrow"
                />
              </button>
              <div class="accordion-content" [hidden]="!accordionState[2]">
                <!-- Content for Location -->
                <div class="accordion-field">
                  <div  class="accordion-field-locations">
                    <span class="label">Location : </span>
                  <div *ngFor="let location of locations" class="attributeValue">
                    {{ location.attributeValue }}
                  </div>
                  </div>
  
  
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="selectedCount > 0" class="footer">
          <app-button  class="spacing"
          type="submit"
          size="xsmall"
          theme="secondary" (click)="clearSelection()">Clear Selection</app-button>
          <app-button  class="spacing"
            type="submit"
            size="xsmall"
            theme="secondary" (click)="onGroupSelection()">Group Selection ({{ selectedCount }})</app-button>
        </div>
      </form>
    </div>
  </div>
  