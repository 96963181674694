import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.scss']
})
export class UserSearchComponent {
  @Input() searchOptions: any[] = [];
  @Output() searchData = new EventEmitter<any>();

  searchStr: string = '';
  activeCol: { title: string; value: string }[] = [];

  changeIndex(event: any) {
    this.activeCol = this.searchOptions.filter(
      (option) => option.value === event
    );
  }

  removeClick() {
    this.searchStr = '';
    this.searchData.emit({
      searchBy: this.activeCol[0]?.value || this.searchOptions[0].value,
      searchText: ''
    });
  }

  search() {
    if (this.searchStr) {
      this.searchData.emit({
        searchBy: this.activeCol[0]?.value || this.searchOptions[0].value,
        searchText: this.searchStr
      });
    }
  }
}
