import { animate, style, transition, trigger } from '@angular/animations';
import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  EmployeeData,
  ITicketSnackbarConfig,
  MODALS,
  ROUTES
} from '@common/interfaces';

import { CommonHttpService } from 'libs/common/src/lib/service/common-http.service';
import { ModalService } from 'libs/common/src/lib/service/modal.service';
import { TicketGenerateAlertModalComponent } from 'libs/shared/ui-components/src/lib/ticket-generate-alert-modal/ticket-generate-alert-modal.component';
import { ViewTicketModalComponent } from 'libs/shared/ui-components/src/lib/view-ticket-modal/view-ticket-modal.component';
@Component({
  selector: 'app-employee-details',
  templateUrl: './employee-details.component.html',
  styleUrls: ['./employee-details.component.scss'],
  animations: [
    trigger('basicAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [animate('100ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class EmployeeDetailsComponent implements OnInit {
  photoUrl!: string;
  adminMail!: string;
  alertBox: boolean = false;
  placeholderUrl = 'assets/160x160/profile-picture-placeholder.svg';
  snackBarConfig!: ITicketSnackbarConfig;
  @ViewChild('generateTicketDetails')
  _generateTicketDetails!: TicketGenerateAlertModalComponent;
  @ViewChild('ticketDetails') _ticketDetails!: ViewTicketModalComponent;
  toggleRequestAnAssetModal: any;
  toggleAlertModal: boolean = false;
  toggleViewTicketModal: boolean = false;
  userId!: string;
  showAlertModal(alertModalConfig: any, from: string) {
    if (from === 'raise_issue') {
      this.toggleRaiseTicketModal = false;
      if (alertModalConfig.showOnlySnackbar) {
        this.snackBarConfig = alertModalConfig;
        this.alertBox = true;
      } else {
        this._generateTicketDetails.ngOnInit(alertModalConfig);
        this.toggleAlertModal = true;
      }
    } else if (from === 'request_an_asset') {
      this.toggleRequestAnAssetModal = false;
      if (alertModalConfig.showOnlySnackbar) {
        this.snackBarConfig = alertModalConfig;
        this.alertBox = true;
      }
    }
    else if (from === 'access_installation') {
      this.toggleRequestAnAssetModal = false;
      if (alertModalConfig.showOnlySnackbar) {
        this.snackBarConfig = alertModalConfig;
        this.alertBox = true;
      }
    }
  }
  navigateToTicket(ticketId: string) {
    this.router.navigate([ROUTES.TICKET_DETAILS + ticketId]);
  }
  userData!: any;
  loading: boolean = true;
  employeeData: EmployeeData = {
    'Full Name': '',
    'Employee ID': '',
    Designation: '',
    'Email ID': '',
    'Contact No': '',
    'Reporting Manager': '',
  };
  isAdmin: boolean =
    JSON.parse(localStorage.getItem('userInfo') || '{}')?.roles[0] ===
    'ADMIN';
  toggleRaiseTicketModal: any;
  constructor(
    private _location: Location,
    private route: ActivatedRoute,
    private commonHttpService: CommonHttpService,
    private modalService: ModalService,
    private router: Router,

  ) { }
  ngOnInit(): void {

    this.route.params.subscribe((params) => {
      this.isAdmin =
        this.isAdmin && localStorage.getItem('userId') === Object(params).id;
      this.getEmployeeDetails(Object(params).id);
    });
    this.userData = JSON.parse(localStorage.getItem('userInfo') as string);
    if (this.router.url.includes('employee-dashboard') || this.router.url.includes('profile-details')) {
      this.userId = localStorage.getItem('userId') || '';
    }
    else {
      const length = this.router.url.split('/').length;
      this.userId = this.router.url.split('/')[length - 1];
    }
    this.adminMail = this.userData.email;
  }
  getEmployeeDetails(userId: string) {
    this.commonHttpService
      .getUserDetailsById(userId)
      .subscribe((result: any) => {
        this.loading = false;
        let userDetails = result?.data[0];
        this.employeeData['Full Name'] =
          userDetails.firstName + ' ' + userDetails.lastName;
        this.employeeData['Contact No'] = userDetails.phoneNumber;
        this.employeeData['Email ID'] = userDetails.email;
        this.employeeData['Employee ID'] = userDetails.employeeId;
        this.employeeData['Designation'] = userDetails.designation;
        this.employeeData['Reporting Manager'] =
          userDetails.reportingManagerName;
        this.photoUrl = userDetails.profilePictureUrl;
      });
  }
  isLoanee: boolean = false;
  canImageBeShown() {
    if (this.router.url.includes('employee-details')) {
      if (this.adminMail === this.employeeData['Email ID']) {
        this.isAdmin = true;
        this.isLoanee = false;
        return true;
      }
      this.isAdmin = false;
      this.isLoanee = true;
      return true;
    } else if (this.router.url.includes('profile-details')) {
      this.isAdmin =
        JSON.parse(localStorage.getItem('userInfo') || '{}')?.roles[0] ===
        'ADMIN';
      this.isLoanee = false;
      return true;
    }
    return false;
  }
  goBack() {
    this._location.back();
  }

  triggerModal(modal: string) {
    if (modal === MODALS.RAISE_TICKET_MODAL) {
      this.modalService.triggerModal(modal)?.subscribe((result) => {
        if (result) this.showAlertModal(result, 'raise_issue');
      })
    } else if (modal === MODALS.REQUEST_ASSET_MODAL) {
      this.modalService.triggerModal(modal)?.subscribe((result) => {
        if (result) this.showAlertModal(result, 'request_an_asset');
      })
    }
    else if (modal === MODALS.ACCESS_INSTALLATION_MODAL) {
      this.modalService.triggerModal(modal)?.subscribe((result) => {
        if (result) this.showAlertModal(result, 'access_installation');
      })
    }
  }
}
