import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-project-search',
  templateUrl: './project-search.component.html',
  styleUrls: ['./project-search.component.scss']
})
export class ProjectSearchComponent {
  @Output() searchData = new EventEmitter<any>();

  searchStr: string = '';
  activeCol: { title: string; value: string }[] = [];
  searchOptions = [
    { title: 'Project Name', value: 'PROJECT_NAME' }
  ];

  changeIndex(event: any) {
    this.activeCol = this.searchOptions.filter(
      (option) => option.value === event
    );
  }

  removeClick() {
    this.searchStr = '';
    this.searchData.emit({
      searchBy: this.activeCol[0]?.value || 'PROJECT_NAME',
      searchText: ''
    });
  }

  search() {
    if (this.searchStr) {
      this.searchData.emit({
        searchBy: this.activeCol[0]?.value || 'PROJECT_NAME',
        searchText: this.searchStr
      });
    }
  }
}
