import { Dialog, DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ROUTES } from 'libs/common/src/lib/constants/routes.constants';
import { CommonHttpService } from 'libs/common/src/lib/service/common-http.service';

@Component({
  selector: 'app-asset-type-detail',
  templateUrl: './asset-type-detail.component.html',
  styleUrls: ['./asset-type-detail.component.scss']
})
export class AssetTypeDetailComponent implements OnInit {
  assetTypeId!: string;
  assetDetails: any;
  groupedConfigs: { [key: string]: any[] } = {};
  loaderView: boolean = false;
  constructor(
    private commonHttpService: CommonHttpService,
    @Inject(DIALOG_DATA) public data: any,
    private dialogRef: DialogRef,private router: Router,  private activatedRoute: ActivatedRoute,
  ) {
    this.assetTypeId = data.id;
  }

  ngOnInit(): void {
    this.fetchAssetDetails();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  fetchAssetDetails() {
    this.loaderView =  true;
    this.commonHttpService.getAssetTypeDetails(this.assetTypeId).subscribe({
      next: (response: any) => {
        this.assetDetails = response.data;
        this.groupConfigsByCategory();
        this.loaderView =  false;

      },
      error: (error) => {
        console.error('Error fetching asset details:', error);
      }
    });
  }

  groupConfigsByCategory() {
    this.assetDetails.configs.forEach((config: any) => {
      if (!this.groupedConfigs[config.category]) {
        this.groupedConfigs[config.category] = [];
      }
      this.groupedConfigs[config.category].push(config);
    });
  }

  onEditAssetType() {
    this.router.navigate([ROUTES.ADD_ASSET_TYPE], {
      relativeTo: this.activatedRoute,
      queryParams: {
        id: this.assetTypeId,
        name: this.assetDetails.name,
        assetTypeCode: this.assetDetails.assetTypeCode,
        editMode: true,
        // Add other necessary query parameters here
      },
    });
    this.closeDialog();
  }
}
